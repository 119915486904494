import { connect } from 'react-redux';
import {
  configServiceFetchRequest, wsConnect, wsDisconnect,
} from 'componentlibrary';
import App from '../../Components/App';
import {
  handleAuthSession, authorize, exchangeCodeForTokens, logout
} from '../../reducers/auth';
import { getAppConfig } from '../../reducers/appConfig';
import { refreshMicroFrontend } from '../../reducers/microFrontend';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  appConfig: state.appConfig,
  permission: state.auth.permission,
  userEmail: state.auth.idToken,
  showAuthConfirm: state.auth.showAuthConfirm,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  handleAuthSession(...args) {
    return handleAuthSession(dispatch, ...args);
  },
  authorize(...args) {
    return authorize(dispatch, ...args);
  },
  exchangeCodeForTokens(...args) {
    return exchangeCodeForTokens(dispatch, ...args);
  },
  getAppConfig(...args) {
    return getAppConfig(dispatch, ...args);
  },
  refreshMicroFrontend(...args) {
    return refreshMicroFrontend(dispatch, ...args);
  },
  logout(...args) {
    return logout(dispatch, ...args);
  },
  wsConnect: (value) => dispatch(wsConnect(value)),
  configServiceFetchRequest: (value) => dispatch(configServiceFetchRequest(value)),
  wsDisconnect: () => dispatch(wsDisconnect()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
