import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import { inlineLoader } from '../../sass/modules/loader.module.scss';

class MicroFrontend extends React.Component {
  static async loadManifest(host) {
    const result = await fetch(`${host}/asset-manifest.json`);
    const manifest = await result.json();

    return manifest;
  }

  static async loadStyleSheet(host, id) {
    const manifest = await MicroFrontend.loadManifest(host);

    if (!manifest.files['main.css']) {
      return;
    }

    const link = document.createElement('link');
    link.id = id;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `${host}${manifest.files['main.css']}`;
    link.media = 'all';

    document.head.appendChild(link);
  }

  static removeStyleSheet(id) {
    const sheet = document.getElementById(id);
    if (!sheet) {
      return;
    }

    sheet.disabled = true;
    sheet.parentNode.removeChild(sheet);
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      refreshKey: null,
      language: null
    };

    this.renderMicroFrontend = this.renderMicroFrontend.bind(this);
  }

  componentDidMount() {
    const { host, document } = this.props;

    if (document.getElementById(this.scriptId)) {
      this.renderMicroFrontend();
    } else {
      this.loadScript(host, this.scriptId);
    }

    MicroFrontend.loadStyleSheet(host, this.styleId);
  }

  componentDidUpdate() {
    const { name, microFrontend, currentLanguage } = this.props;

    const { refreshKey, language } = this.state;
    const containerRefreshKey = microFrontend.containerRefreshKeys[name];

    if (currentLanguage !== language) {
      this.renderMicroFrontend(containerRefreshKey);
      this.setState({ language: currentLanguage });
    }

    if (!containerRefreshKey) {
      return;
    }

    if (containerRefreshKey !== refreshKey) {
      this.renderMicroFrontend(containerRefreshKey);
      this.setState({ refreshKey: containerRefreshKey });
    }
  }

  componentWillUnmount() {
    MicroFrontend.removeStyleSheet(this.styleId);

    const { name, window } = this.props;
    const unmount = window[`unmount${name}`];

    if (!unmount) {
      return;
    }

    unmount(this.containerName);
  }

  get microFrontendId() {
    const { name } = this.props;
    return `micro-frontend-${name}`;
  }

  get scriptId() {
    return `${this.microFrontendId}-script`;
  }

  get styleId() {
    return `${this.microFrontendId}-style`;
  }

  get containerName() {
    const { name } = this.props;
    return `${name}-container`;
  }

  get userContext() {
    const { idToken } = this.props;

    if (!idToken) {
      return null;
    }

    return {
      uuid: idToken['custom:forwood_uuid'],
      username: idToken.preferred_username,
      company: idToken['custom:company_uuid'],
    };
  }

  loading(state) {
    const { loading } = this.state;
    if (state !== loading) {
      this.setState({
        loading: state
      });
    }
  }

  async loadScript(host, id) {
    this.loading(true);

    const manifest = await MicroFrontend.loadManifest(host);

    const script = document.createElement('script');
    script.id = id;
    script.crossOrigin = '';
    script.src = `${host}${manifest.files['main.js']}`;
    script.onload = () => this.renderMicroFrontend();

    document.head.appendChild(script);
  }

  renderMicroFrontend(refreshKey = null) {
    const {
      getIdToken,
      currentLanguage,
      permission,
      name,
      window,
      history,
    } = this.props;

    const context = {
      getIdToken,
      refreshKey,
      permission,
      currentLanguage,
      user: this.userContext,
    };

    const renderContainer = window[`render${name}`];

    if (!renderContainer) {
      return;
    }

    renderContainer(this.containerName, history, context);
    this.loading(false);
  }

  render() {
    const { loading } = this.state;
    return (
      <Spin
        spinning={loading}
        size="large"
        style={{
          marginTop: 40,
          position: 'fixed',
          top: '20%',
          bottom: '20%'
        }}
        className={inlineLoader}
      >
        <main id={this.containerName} />
      </Spin>
    );
  }
}

MicroFrontend.defaultProps = {
  document,
  window,
  idToken: null
};

MicroFrontend.propTypes = {
  name: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  getIdToken: PropTypes.func.isRequired,
  permission: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  microFrontend: PropTypes.object.isRequired,
  document: PropTypes.object,
  window: PropTypes.object,
  idToken: PropTypes.object,
};

export default MicroFrontend;
