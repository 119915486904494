import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import MicroFrontend from './Containers/MicroFrontend';
import Home from './Components/Home';
import Config from './Config';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/
const Root = ({ route }) => (
  <div>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </div>
);

const TeamsMicroFrontend = ({ history }) => (
  <MicroFrontend
    history={history}
    host={Config.reactApp.TEAMS_MICROFRONTEND_URL}
    name="TeamsMicroFrontend"
  />
);

const TargetsMicroFrontend = ({ history }) => (
  <MicroFrontend
    history={history}
    host={Config.reactApp.TARGETS_MICROFRONTEND_URL}
    name="TargetsMicroFrontend"
  />
);

export const routes = [{
  component: Root,
  routes: [
    {
      path: '/',
      exact: true,
      component: Home
    },
    {
      path: '/teams',
      component: TeamsMicroFrontend,
      exact: false
    },
    {
      path: '/targets',
      component: TargetsMicroFrontend,
      exact: false
    }
  ]
}];

Root.propTypes = {
  route: PropTypes.object.isRequired
};

TeamsMicroFrontend.propTypes = {
  history: PropTypes.object.isRequired
};

TargetsMicroFrontend.propTypes = {
  history: PropTypes.object.isRequired
};
