export const hashedLanguageOptions = {
  en: {
    translationKey: 'language:english',
    key: 'en',
  },
  fr: {
    translationKey: 'language:french',
    key: 'fr',
  },
  es: {
    translationKey: 'language:spanish',
    key: 'es',
  },
  mn: {
    translationKey: 'language:mongolian',
    key: 'mn',
  },
  pt: {
    translationKey: 'language:portuguese',
    key: 'pt',
  },
  ru: {
    translationKey: 'language:russian',
    key: 'ru',
  },
  is: {
    translationKey: 'language:icelandic',
    key: 'is',
  },
  nb: {
    translationKey: 'language:norwegian',
    key: 'nb',
  },
  zh: {
    translationKey: 'language:chinese',
    key: 'zh',
  },
  id: {
    translationKey: 'language:indonesian',
    key: 'id',
  },
  tpi: {
    translationKey: 'language:tokpisin',
    key: 'tpi',
  },
  nl: {
    translationKey: 'language:dutch',
    key: 'nl',
  },
  de: {
    translationKey: 'language:german',
    key: 'de',
  },
  it: {
    translationKey: 'language:italian',
    key: 'it',
  },
  sr: {
    translationKey: 'language:serbian',
    key: 'sr',
  },
  fi: {
    translationKey: 'language:finnish',
    key: 'fi',
  },
  ja: {
    translationKey: 'language:japanese',
    key: 'ja',
  },
  pl: {
    translationKey: 'language:polish',
    key: 'pl',
  },
  hi: {
    translationKey: 'language:hindi',
    key: 'hi',
  },
  uk: {
    translationKey: 'language:ukrainian',
    key: 'uk',
  },
  el: {
    translationKey: 'language:greek',
    key: 'el',
  },
  tr: {
    translationKey: 'language:turkish',
    key: 'tr',
  },
};
export const languageOptions = Object.values(hashedLanguageOptions);

// This method will return the language option in our defined list that first
// match with the input languages.
//
// The input languages array should be taken from i18next.languages property.
// The array is populated with current selected language code, followed by less-specific
// versions of that code and then followed by the list of fallback languages.
export function getcurrentlanguage(languages) {
  if (!languages) {
    return null;
  }

  for (let i = 0; i < languages.length; i++) {
    if (hashedLanguageOptions[languages[i]]) {
      return hashedLanguageOptions[languages[i]];
    }
  }
  // Return null if not found
  return null;
}
