import Config from '../Config';

const APP_CONFIG_SET_ACTION = 'APP_CONFIG_SET';

export function appConfigDefaultState() {
  return null;
}

export async function getAppConfig(dispatch) {
  const result = await fetch(`${Config.apiGateway.URL}/app-config`);
  let payload = await result.json();
  payload = {
    maintenanceMode: payload.maintenance_mode,
    appVersion: payload.app_version,
    supportMenuEnabled: payload.support_menu_enabled,
    appclientId: payload.app_client_id,
  };
  sessionStorage.setItem('appClientId', payload.appclientId);
  dispatch({
    type: APP_CONFIG_SET_ACTION,
    payload
  });
}

export default (state = appConfigDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case APP_CONFIG_SET_ACTION:
      return payload;

    default:
      return state;
  }
};
